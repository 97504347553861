<template>
  <div class="full-line full-height flex-col" style="background-color: #f2f2f2;">
    <personSelect v-model="persons" :disabled="false"></personSelect>
    <comment id="4616935712021046902"></comment>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      persons: [
        {
          name: '陈杰',
          id: 'F4DDEA55424BD7F3'
        },
        {
          name: '陈文彬',
          id: 'ECC96F9DB383AC9D'
        }
      ],
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
